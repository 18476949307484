import { DateUtils } from 'react-frontend-utils';

import { Application } from '../models/Application'


/**
 * Destructures a string into a type and a value, where the type is separated from the value by a colon.
 * The string is split only once at the first occurrence of the ":" and any further occurrences are ignored.
 * @param {String} str The string to destructure.
 * @returns {Array} An array with two elements: the type and the value
 */
export function destructureArgument(arg) {
    const index = arg.indexOf(":");
    if (index === -1) {
        console.error("No : separator found in string: " + arg);
        return [arg];
    }
    
    return [arg.substring(0, index), arg.substring(index + 1)];
}


//--------------------------------------GET FIELD, STRING, IMAGE, OR NUMERIC VALUE FOR ARGUMENT TYPE ---------------------------------------




/**
 * Interpret the argument provided in the form TYPE:VAL, as the type supplied
 * @param {String} argument the token which has a TYPE:ARG
 * @param {String} type to interpret as, one of "NUMBER", "STRING", "IMAGE"
 * @param {Object} fieldTree the base of the field tree from an Application
 * @returns {Object} the interpreted value
 */
export function interpretArgument(argument, type, fieldTree) {
    
    switch (type) {
        case "NUMBER":
            return getNumericValue(argument, fieldTree);
        case "STRING":
            return getStringValue(argument, fieldTree);
        case "IMAGE":
            return getImageValue(argument, fieldTree);
        default:
            throw new Error("Cannot interpret argument (" + argument + ") as type " + type);
    }
    
}




/**
 * Get a numeric value for the argument (token), converting if possible, returning NaN if impossible
 * @param {String} argument the token which has a TYPE:ARG
 * @param {Object} fieldTree the base of the field tree from an Application
 * @returns {Number} the number, which may be NaN
 */
export function getNumericValue(argument, fieldTree) {
        
    const [argumentType, arg] = destructureArgument(argument);  //destructure type and value

    switch (argumentType) {
        case "STRING":
        case "EMPTY":
            return Number.NaN;
        case "NUMBER":
            return Number(arg); //just the number
        case "VARIABLE":
            const fieldSet = Application.findFieldByName(fieldTree, arg);  //get {parent, field}
            if (!fieldSet)
                throw new Error("Unknown Variable \"" + arg + "\"");
                
            return numberForField(fieldSet.field);
            
        default:
            throw new Error("Unknown Argument type: " + argumentType);
        
    }
}

/**
 * Get a string value for the argument (token), converting if possible, returning null if impossible
 * @param {String} argument the token which has a TYPE:ARG
 * @param {Object} fieldTree the base of the field tree from an Application
 * @returns {String} the String, which may be null
 */
export function getStringValue(argument, fieldTree) {
                
    const [argumentType, arg] = destructureArgument(argument);  //destructure type and value

    switch (argumentType) {
        case "NUMBER":
            return null;
        case "EMPTY":
            return "";
        case "STRING":
            return arg;  //the string itself
        case "VARIABLE":
            const fieldSet = Application.findFieldByName(fieldTree, arg);  //get {parent, field}
            if (!fieldSet)
                throw new Error("Unknown Variable \"" + arg + "\"");
                
            return stringForField(fieldSet.field);
            
        default:
            throw new Error("Unknown Argument type: " + argumentType);   
    }
}


/**
 * Get a image value for the argument (token), converting if possible, returning null if impossible
 * @param {String} argument the token which has a TYPE:ARG
 * @param {Object} fieldTree the base of the field tree from an Application
 * @returns {Image} the base64Image, which may be null
 */
function getImageValue(argument, fieldTree) {
        
    const [argumentType, arg] = destructureArgument(argument);  //destructure type and value

    switch (argumentType) {
        case "NUMBER":
        case "STRING":
        case "EMPTY":
            return null;
        case "VARIABLE":
            const fieldSet = Application.findFieldByName(fieldTree, arg);  //get {parent, field}
            if (!fieldSet)
                throw new Error("Unknown Variable \"" + arg + "\"");
                
            return imageForField(fieldSet.field);
            
        default:
            throw new Error("Unknown Argument type: " + argumentType);   
    }
}


//----------------------------------------- FROM THE FIELD, GET A NUMBER, IMAGE, OR STRING --------------------------------




/**
 * Based on the field type, interpret the data in the field to provide a number as the field variable. 
 * @param {Object} field the field to extract the data from.  If the field data is null, return NaN
 * @returns {Number} a numeric value, which may be NaN
 */
export function numberForField(field) {
        
    switch (field.type) {
        
        case "FieldGroup":                             //These types attempt to convert the patron data into a Number, which may end up as NaN or 0 if null/empty
        case "PagedFieldGroup":
        case "CloningFieldGroup":  
        case "AddressField":
        case "TextField":
            if (!field.patronData)
                return Number.NaN;
            //fallthrough
        
        case "NumericField":
            return Number(field.patronData);            //0 on null patron data
    
        case "DateField":                               //Return the number of seconds since the epoch in UTC (or NaN if date is null)
            if (!field.patronData)
                return Number.NaN;
            
            const date = DateUtils.parseJsonDate(field.patronData, true);
            return date.getTime()/1000.0;
            
        case "ImageField":                              //1 if we have an image, 0 if we don't
            return field.patronData ? 1 : 0;
            
        case "SelectField":                             //Index of the selected field.  0 = none selected, 1 = first selected, 2 = second selected, etc.
            if (!field.patronData)
                return 0;
                
            let index = 1;
            for (const item of field.selectableItems) {
                if (field.patronData === item)
                    return index;
                index++;
            }
            return 0;
            
        case "CheckboxField":                           //1 if checked, 0 if unchecked
            return field.patronData === "true" ? 1 : 0;

        default:
            return Number.NaN;
    }
}



/**
 * Based on the field type, interpret the data in the field to provide a string as the field variable. 
 * @param {Object} field the field to extract the data from.  If the field data is null, return null
 * @returns {String} a string value, which may be null
 */
export function stringForField(field) {
      
    switch (field.type) {
            
        case "AddressField":
        case "TextField":
        case "SelectField":
             if (!field.patronData)
                return "";
            return field.patronData;       //the text itself

        default:
            return null;        //not possible to convert to string
    }
}




/**
 * Based on the field type, interpret the data in the field to provide a string base64 image as the field variable. 
 * @param {Object} field the field to extract the data from.  If the field data is null, return empty string
 * @returns {String} a string value, which may be null
 */
function imageForField(field) {
        
    if (field.type === "ImageField" || field.type === "SignatureField")  {                             
        if (!field.patronData)
            return "";
            
        return field.patronData; 
    }

    return null;        //not possible to convert to image
    
}



