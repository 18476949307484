import { interpretArgument, numberForField, stringForField, getNumericValue, getStringValue, destructureArgument } from './FieldData';

import { Application } from '../models/Application'
import { DateUtils, Random } from 'react-frontend-utils';


const functionDefinitions = [{name: "round", argTypes: ["NUMBER"], func: round, returnType: "NUMBER"},
                             {name: "abs", argTypes: ["NUMBER"], func: abs, returnType: "NUMBER"},
                             {name: "countEach", argTypes: ["STRING"], func: countEach, returnType: "NUMBER"},
                             {name: "countIfEq", argTypes: ["STRING", "RAW"], func: countIfEq, returnType: "NUMBER"},
                             {name: "sumEach", argTypes: ["STRING"], func: sumEach, returnType: "NUMBER"},
                             {name: "sibling", argTypes: ["STRING"], func: sibling, returnType: "VARIABLE"},
                             {name: "children", argTypes: ["RAW"], func: children, returnType: "NUMBER"},
                             {name: "exists", argTypes: ["STRING", "RAW"], func: exists, returnType: "RAW"},
                             {name: "if", argTypes: ["NUMBER", "RAW", "RAW"], func: ifFunc, returnType: "RAW"},
                             {name: "number", argTypes: ["RAW"], func: number, returnType: "NUMBER"},
                             {name: "random", argTypes: ["NUMBER", "STRING"], func: random, returnType: "STRING"},
                             {name: "now", argTypes: [], func: now, returnType: "NUMBER"},
                             {name: "dateVal", argTypes: ["STRING"], func: dateVal, returnType: "NUMBER"},
                             {name: "dateStr", argTypes: ["NUMBER"], func: dateStr, returnType: "STRING"},
                             {name: "age", argTypes: ["NUMBER"], func: age, returnType: "NUMBER"},
                             {name: "str", argTypes: ["RAW"], func: str, returnType: "STRING"},
                             {name: "strlen", argTypes: ["STRING"], func: strlen, returnType: "NUMBER"},
                             {name: "contains", argTypes: ["STRING", "STRING"], func: contains, returnType: "NUMBER"},
                             {name: "strSplit", argTypes: ["STRING", "STRING", "NUMBER"], func: strSplit, returnType: "STRING"},
                             {name: "strSlice", argTypes: ["STRING", "NUMBER", "NUMBER"], func: strSlice, returnType: "STRING"}
                            ];



/**
 * Call the pre-defined function with the specified name and provided arguments
 * @param {String} name function name
 * @param {Array} args array of function arguments, in the form TYPE:VAL
 * @param {Object} fieldTree the base of the field tree from an Application
 * @param {Object} self is a special field that can be referenced as a variable - referring to a particular field that uses a calculation. If "self" is not 
 *                 provided then self will refer to the base field tree
 * @returns {String} result in the form TYPE:VAL to be pushed back on the stack
 */
export function functionCall(name, args, fieldTree, self) {
    for (const functionDefinition of functionDefinitions) {

        if (name === functionDefinition.name) { //found it
            
            //Check right number of arguments
            if (args.length !== functionDefinition.argTypes.length)
                throw new Error("Function Error: Wrong number of arguments to function \"" + name + "\": " + functionDefinition.argTypes.length + " required, " + args.length + " supplied");
            
            //Interpret the arguments to the desired type
            const argVals = [];
            for (let i=0; i<args.length; i++) {
                
                let argVal;
                if (functionDefinition.argTypes[i] === "RAW")  //pass the raw argument, don't interpret
                    argVal = args[i];
                else {
                    argVal = interpretArgument(args[i], functionDefinition.argTypes[i], fieldTree);

                    if (argVal === null)
                        throw new Error("Function Error: Argument " + (i+1) + " of function \"" + name + "\": invalid: expected an argument of type " + functionDefinition.argTypes[i]);
                }

                argVals.push(argVal);
            }
            
            console.log("Calling ", name, "() with ", argVals)

            //Make the function call
            const result = functionDefinition.func(self, fieldTree, ...argVals);
            console.log("Function Result: ", result)
            if (functionDefinition.returnType === "RAW")
                return result;      //already coded

            return functionDefinition.returnType + ":" + result;
        }
   
    }
    //not found
    throw new Error("Unknown function named \"" + name + "\"");

   
}



function round(self, fieldTree, number) {
    return Math.round(number);
}

function abs(self, fieldTree, number) {
    return Math.abs(number);
}


function sibling(self, fieldTree, siblingName) {
   
    const parent = Application.parentOf(self, fieldTree);  //will be the parent or the field tree
    if (parent === null)
        throw new Error("Could not find parent of self");

    for (const child of parent.children) {
        if (child.name === siblingName)
            return Application.uniqueFieldName(child);  //found the sibling
    }
    
    throw new Error("Could not find sibling named \"" + siblingName + "\"");

}

function children(self, fieldTree, argument) {
    
    const [argumentType, arg] = destructureArgument(argument);  //destructure type and value

    if (argumentType !== "VARIABLE")
        throw new Error("Function Error: Argument 1 of function \"children\": invalid: expected an argument of type VARIABLE");

    const fieldSet = Application.findFieldByName(fieldTree, arg);  //get {parent, field}
    if (!fieldSet)
        throw new Error("Unknown Variable \"" + arg + "\"");
 
    const childrenArray = fieldSet.field.children;
    
    if (!childrenArray)  //undefined for non-field groups
        return 0;

    return childrenArray.length;

}



function exists(self, fieldTree, fieldName, falseResult) {
    

    const fieldSet = Application.findFieldByName(fieldTree, fieldName);  //get {parent, field}
    if (!fieldSet)
        return falseResult;
 
    return "VARIABLE:" + fieldSet.field.name;

}




function countEach(self, fieldTree, fieldNamePrefix) {
    
    const allFields = Application.findAllFieldsNamed(fieldNamePrefix, fieldTree);
    return allFields.length;
    
}

function countIfEq(self, fieldTree, fieldNamePrefix, arg) {
    
    const allFields = Application.findAllFieldsNamed(fieldNamePrefix, fieldTree);
    let count = 0;
    for (const field of allFields) {

        const [argumentType, argVal] = destructureArgument(arg);  //destructure type and value

        if (argumentType === "STRING") {
            if (stringForField(field) === argVal)
                count++;
        }
        else if (argumentType === "NUMBER") {
            if (numberForField(field) === Number(argVal))
                count++;
        }
        else if (argumentType === "VARIABLE") {
            const val = getNumericValue(argVal, fieldTree); 
            if (val === Number(argVal))
                count++;
        }
        else
            throw new Error("Function Error: Argument 2 of function \"countIfEq\": invalid: expected an argument of type STRING or NUMBER");


    }
    return count;
}



function sumEach(self, fieldTree, fieldNamePrefix) {
    
    const allFields = Application.findAllFieldsNamed(fieldNamePrefix, fieldTree);
    return allFields.reduce((sum, field) => sum + numberForField(field), 0);
    
}
    
    
function ifFunc(self, fieldTree, evaluatedExpression, truthArg, falseArg) {

    if (evaluatedExpression)
        return truthArg;
    else
        return falseArg;

}

function random(self, fieldTree, digits, type) {

    type = type.toLowerCase();
    switch (type) {
        case "numeric":
            return Random.randomNumber(digits).toString();
        case "alphanumeric":
            return Random.randomString(digits);
        default:
            throw new Error("Function Error: Argument 2 of function \"random\": invalid: expected an argument of type STRING equal to \"numeric\" or \"alphanumeric\"");
    }
}


function now() {
    return Date.now()/1000.0;  //to seconds
}

function dateVal(self, fieldTree, dateString) {
    const date = DateUtils.parseDateTime(dateString, DateUtils.DateFormatType.ISO8601);
    return date.valueOf()/1000.0;  //to seconds
}


function dateStr(self, fieldTree, dateVal) {
    if (isNaN(dateVal))
        return "";
    const date = new Date(dateVal * 1000);  //to millis
    return DateUtils.dateFormat(date, DateUtils.DateFormatType.ISO8601);
}


function age(self, fieldTree, dateInSeconds) {

    if (isNaN(dateInSeconds))
        return Number.NaN;

    const date = new Date(dateInSeconds * 1000);  //to millis
    let now = new Date();

    const dateYear = date.getUTCFullYear();
    const thisYear = now.getUTCFullYear();

    let ageYear = thisYear - dateYear;    // get difference in years

    now.setUTCFullYear(dateYear);   //make the dates equal in year, to determine which is bigger

    if (now < date) //not had their birthday yet
        ageYear -= 1;

    if (ageYear < 0)
        return 0;

    return ageYear;
}


function str(self, fieldTree, arg) {
    
    const str = getStringValue(arg, fieldTree);
    if (str !== null)
        return str;

    const number = getNumericValue(arg, fieldTree);
    if (!Number.isNaN(number))
        return number.toString();

    throw new Error("Function Error: Argument 1 of function \"str\": invalid: expected an argument of type STRING or NUMBER");
}



function strlen(self, fieldTree, theString) {
    return theString.length;
}


function contains(self, fieldTree, hay, needle) {
    return hay.includes(needle) ? 1 : 0;
}

function strSplit(self, fieldTree, str, delimiter, index) {
    const parts = str.split(delimiter);
    if (index < 0 || index >= parts.length)
        return "";
    return parts[index];
}

function strSlice(self, fieldTree, str, start, end) {
    return str.slice(start, end);
}



function number(self, fieldTree, arg) {
    
    const number = getNumericValue(arg, fieldTree);
    if (!Number.isNaN(number))
        return number;

    const str = getStringValue(arg, fieldTree);
    return parseFloat(str);
}

