import { Application } from '../models/Application'

import { interpretArgument, destructureArgument} from './FieldData';
import { functionCall } from './FunctionCall';

/**
 * Computes a field calculation using fields in a field tree and an expression in postfix format. The expression may contain
 * literals, operations, functions, and variables, where variables are field values.  The postfix format is a String of tokens separated by spaces 
 * where each token is a TYPE:VALUE, the type identifying the type of token and the value the value of the token.
 *
 * Types are: OPERATOR1, OPERATOR2, GROUP, FUNCTION and VARIABLE, EMPTY, NUMBER, and STRING
 * 
 * OPERATOR1 is a unary operator, OPERATOR2 is a binary operator
 *
 * For supported operations, see FieldCalculation.java. 
 * Literals must be a numeric, decimal value.  Boolean are pre-converted to 1 or 0 from the backend
 * 
 * When evaluating a field, the field type is taken into account to determine how to interpret it. In general the patronValue for the field is 
 * used, with some exceptions. A null value is treated as zero. Field interpretation returns a numeric value or a string. The calculation
 * logic follows javascript standards.
 * 
 * @param {String} postfixExpression the complete expression 
 * @param {Object} fieldTree the base of the field tree from an Application
 * @param {Function} getOtherResults is an function that returns an Array of objects containing other expression results, in case we reference them.  {name, result}
 * @param {Object} self is a special field that can be referenced as a variable - referring to a particular field that uses a calculation. If "self" is not 
 *                 provided then self will refer to the base field tree
 * @returns {String} a token with the result (TYPE:VALUE)
 */
export function fieldCalculator(postfixExpression, fieldTree, getOtherResults, self) {
    
    if (!self)  //If no "self" passed in, then use the base field
        self = fieldTree;  
    
    console.log("Evaluate: ", postfixExpression, " with self: ", self);

    const stack = [];
    
    const tokens = postfixExpression.split(" ");
    
    for (const token of tokens) {
        
        const [type, tokVal] = destructureArgument(token);  //destructure type and value
        
        let isUnary = true;
        switch (type) {
            case "VARIABLE":
            case "NUMBER":
            case "EMPTY":
                stack.push(token);
                break;    

            case "STRING":
                const restoredString = token.replaceAll("~", " ");  //The ~ symbol replaces a space because spaces are delimiters in the postfix expression
                stack.push(restoredString);
                break;
            
            case "OPERATOR2":
                isUnary = false;
                if (tokVal === ".") {  // dot operator is a special one that references parent/child field
                    evaluateChildField(stack, fieldTree);
                    break;
                }
                //fallthrough
            case "OPERATOR1":
                const result = evaluateOperator(tokVal, isUnary, stack, fieldTree);
                stack.push(result);
                break;
            case "EXPRESSION":
                const otherResults = getOtherResults();  //get the most recent results
                const expResult = getExpressionResult(stack, otherResults);
                stack.push(expResult);
                break; 
            
            case "FUNCTION":
                const funcResult = evaluateFunction(stack, tokVal, fieldTree, self);
                stack.push(funcResult);
                break;
            
            default:
                throw new Error("Calculation Error: Unknown token type \"" + type + "\" in expression");
            
        }
    }    
        
    //Stack should contain one item, our result
    if (stack.length !== 1) {
        throw new Error("Calculation Error: Malformed expression, no result, parenthesis mismatch?");
    }
    
    console.log("Evaluates to: ", stack[0]);

    return stack[0];
}



/**
 * Create a variable from a field-variable's child and push it back to the stack
 * @param {Array} stack, containing arguments
 * @param {Object} fieldTree the base of the field tree from an Application
 */
function evaluateChildField(stack, fieldTree) {

    const rightArg = stack.pop();
    if (rightArg === undefined)  //stack empty
        throw new Error("Calculation Error: Malformed expression, no right argument for operation");

    const leftArg = stack.pop();
    if (leftArg === undefined)  //stack empty
        throw new Error("Calculation Error: Malformed expression, no left argument for operation");

    const [argumentType, arg] = destructureArgument(leftArg);  //destructure type and value
    
    if (argumentType === "EMPTY") {
        stack.push("EMPTY:0");
        return;
    }

    if (argumentType !== "VARIABLE")
        throw new Error("Left side of . operator must be a variable");

    const fieldSet = Application.findFieldByName(fieldTree, arg);  //get {parent, field}
    if (!fieldSet)
        throw new Error("Unknown Variable \"" + arg + "\"");

    const parent = fieldSet.field;   //the parent is the left side of the .
    
    if (parent.type !== "FieldGroup" && parent.type !== "PagedFieldGroup" && parent.type !== "CloningFieldGroup")
        throw new Error("Field \"" + parent.name + "\" is not a type that has children");
            

    //Dot operator is a special operator that selects an element from a parent        
    const [childType, childVal] = destructureArgument(rightArg);  //destructure type and value    

    let child;
    switch (childType) {
        case "NUMBER":
            const childNumber = parseInt(childVal);
            if (Number.isNaN(childNumber) || childNumber < 0) 
                throw new Error("Right side of . operator must be a child index: (0, 1, 2...)");

            child = parent.children[childNumber];     
            break;

        case "STRING":

            for (const childField of parent.children) {
                if (childField.name === childVal) {
                    child = childField;
                    break;
                }
            }
            break;

        default:
            throw new Error("Right side of . operator must be a child index (0, 1, 2...) or a field name string");

    }

    if (!child) {
        stack.push("EMPTY:0");
        return;
    }
    
    //Get the unique field name, for cloning fields
    stack.push("VARIABLE:" + Application.uniqueFieldName(child));
       
}


/**
 * Find which expression the first stack argument is referencing from otherResults and extract it
 * @param {Array} stack, containing arguments
 * @param {Array} otherResults is an array of objects containing other expression results, in case we reference them.  {name, result}
 * @returns {String} result in the form TYPE:VAL to be pushed back on the stack
 */
function getExpressionResult(stack, otherResults) {
    
    const arg = stack.pop();
    if (arg === undefined)  //stack empty
        throw new Error("Calculation Error: Malformed expression, no variable for other calculation expression in {}");

    const [argumentType, expName] = destructureArgument(arg);  //destructure type and value
    if (argumentType !== "VARIABLE")
        throw new Error("Wrong Argument type for {" + expName + "} expression");

    console.log("Looking for other expression ", expName, " in ", otherResults);
    for (const result of otherResults) {
        if (result.name === expName) {  //found the reference
            if (result.result === undefined)
                throw new Error("Expression {" + expName + "} has not been evaluated yet");
            if (result.result === null)
                throw new Error("Expression {" + expName + "} has an error");
            
            return result.result;
        }
    }    

    throw new Error("No expression named {" + expName + "}");
}



/**
 * Evaluate using the provided operator, with the stack providing the necessary arguments
 * @param {String} operator the operator
 * @param {Int) airy the number of arguments the operator takes (1 or 2)
 * @param {Array} stack, containing arguments
 * @param {Object} fieldTree the base of the field tree from an Application
 * @returns {String} result in the form TYPE:VAL to be pushed back on the stack
 */
function evaluateOperator(operator, isUnary, stack, fieldTree) {

    //Always have the right argument - pop it off the stack
    const rightArg = stack.pop();
    if (rightArg === undefined)  //stack empty
        throw new Error("Calculation Error: Malformed expression, no right argument for operation");

   
    //If binary, pop the left argument off the stack
    let leftArg = null;
    if (!isUnary) { //binary, get left argument
        leftArg = stack.pop();
        if (leftArg === undefined)  //stack empty
            throw new Error("Calculation Error: Malformed expression, no left argument for operation");        
    }
     
    
   
    //If the operator (binary) can operate on an image, try that first, result is always a number
    if (operator === "==" || operator === "!=") {
        const leftImage = interpretArgument(leftArg, "IMAGE", fieldTree);
        const rightImage = interpretArgument(rightArg, "IMAGE", fieldTree);
               
        if (leftImage !== null && rightImage !== null) {
            switch (operator) {
                case "==":
                    return "NUMBER:" + (leftImage === rightImage ? 1 : 0);  //compare strings, return 1 if equal, 0 if not equal
                case "!=":
                    return "NUMBER:" + (leftImage === rightImage ? 0 : 1);  //compare strings, return 0 if equal, 1 if not equal
                default:
                    break;
            }      
        }
    }
   
   
    //If the operator (binary) can operate on text, try that next, result is always a number except for string concatenation
    if (operator === "==" || operator === "!=" || operator === "+") {
        
        const leftString = interpretArgument(leftArg, "STRING", fieldTree);
        const rightString = interpretArgument(rightArg, "STRING", fieldTree);
        
        //If both are valid string arguments, then use them
        if (leftString !== null && rightString !== null) {
            switch (operator) {
                case "==":
                    return "NUMBER:" + (leftString === rightString ? 1 : 0);  //compare strings, return 1 if equal, 0 if not equal
                case "!=":
                    return "NUMBER:" + (leftString === rightString ? 0 : 1);  //compare strings, return 0 if equal, 1 if not equal
                case "+":
                    return "STRING:" + leftString + rightString;  //string concatenation
                default:
                    throw new Error("Calculation Error: Not a string operator");
            }
        }     
    } 
   

    //If neither argument is a string, then we must convert to numbers, result is always a number
    const leftVal = leftArg !== null ? interpretArgument(leftArg, "NUMBER", fieldTree) : null;
    const rightVal = interpretArgument(rightArg, "NUMBER", fieldTree);        
     
    switch (operator) {
        
        case "!":      
            return "NUMBER:" + ((!rightVal) === true ? 1 : 0);                   
        case "^":       
            return "NUMBER:" + (Math.pow(leftVal, rightVal));   //expoonent    
        case "*":
            return "NUMBER:" + (leftVal * rightVal);         
        case "/":
            return "NUMBER:" + (leftVal / rightVal);           
        case "%":      
            return "NUMBER:" + (leftVal % rightVal);  //modulus
        case "+":
            return "NUMBER:" + (leftVal + rightVal);
        case "-":
            return "NUMBER:" + (leftVal - rightVal);
        case "<":
            return "NUMBER:" + (leftVal < rightVal ? 1 : 0);
        case "<=":
            return "NUMBER:" + (leftVal <= rightVal ? 1 : 0);
        case ">":
            return "NUMBER:" + (leftVal > rightVal ? 1 : 0);
        case ">=":
            return "NUMBER:" + (leftVal >= rightVal ? 1 : 0);
        case "&&":
            return "NUMBER:" + (leftVal && rightVal ? 1 : 0);
        case "||":
            return "NUMBER:" + (leftVal || rightVal ? 1 : 0);           
        case "==":     
            return "NUMBER:" + (leftVal === rightVal ? 1 : 0);         
        case "!=":      
            return "NUMBER:" + (leftVal !== rightVal ? 1 : 0);    
        
        default:
            throw new Error("Unknown Operator \"" + operator + "\"");
  
    }

    
}



/**
 * Call a postfix function by popping the number of arguments off the stack, followed by the function name.  Then evaluate the function
 * and return the result.
 * @param {type} stack
 * @param {type} numFuncArgs the number of arguments to the function that are on the stack
 * @param {Object} fieldTree the base of the field tree from an Application
 * @param {Object} self is a special field that can be referenced as a variable - referring to a particular field that uses a calculation. If "self" is not 
 *                 provided then self will refer to the base field tree
 * @returns {String} result in the form TYPE:VAL to be pushed back on the stack
 */
function evaluateFunction(stack, numFuncArgs, fieldTree, self) {
    
    const funcArgs = [];
    
    for (let i=0; i<numFuncArgs; i++) {
    
        const arg = stack.pop();
        if (arg === undefined)  //stack empty
            throw new Error("Calculation Error: Malformed expression, insufficent arguments for function");
        
        funcArgs.unshift(arg);  //insert at beginning
    }
    
    const funcNameArg = stack.pop();
    if (funcNameArg === undefined)  //stack empty
        throw new Error("Calculation Error: Missing function name");
    
    const [argumentType, funcName] = destructureArgument(funcNameArg);  //destructure type and value
    if (argumentType !== "VARIABLE")
        throw new Error("Calculation Error: Missing function name");
    
    const result = functionCall(funcName, funcArgs, fieldTree, self);
    return result;   
}







//---------------------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

/**
 * Convenience call for updating calculations. We can reference any previous calculation.
 * 
 * @param {Application} application the Application object holding the calculation fields
 * @param {Function} onError callback if an error occurs, taking a String argument describing the error
 */
 export function refreshApplicationCalculations(application, onError) {
    
    
    const getAllCalculationResults = () => {
        //Grab all the computed results and place them in an array so some expressions can reference other results
        const calcResults = [];
        for (const calculation of application.fieldCalculations)
            calcResults.push({name: calculation.resultName, result: calculation.result});
        
        return calcResults;
    };
    

    //Update all Application calculations
    for (const calc of application.fieldCalculations) {
        try {
            const result = fieldCalculator(calc.postfixExpression, application.fieldTree, getAllCalculationResults);
            if (calc.isPaymentLineItem) {
                const numberResult = interpretArgument(result, "NUMBER", application.fieldTree);
                if (Number.isNaN(numberResult))
                    throw new Error("Calculation \"" + calc.resultName + "\" result does not evaluate to a number, which is required when Calculation is a payment line item");
            }

            calc.result = result;
            calc.error = null;
        } catch (error) {
            calc.result = null;
            calc.error = "\"" + calc.resultName + "\":" + error.toString();
        }
    }

    //Walk through all the fields and make the calculations for hidden fields
    try {
        application.showHideFields(null, getAllCalculationResults);
    } catch (error) {
        onError(error.message);  //just log the error
    }
    
    //Walk all the fields and set the calculation results to the patronData of any CalcResultFields
    application.setCalculationResults();
    
    
}

/**
 * Computes the sum of all calculations which are payment line items. Note: this function should only be called after calling the above refreshApplicationCalculations() function.
 * If no calculations are payment line items, return 0. Note: it is possible to have computed a negative value, which should be handled appropriately by the caller.
 * 
 * @param {Application} application the Application holding the calculation fields
 * @returns {Number} the sum of all payment line items
 */
export function computePaymentSum(application) {

    let sum = 0.0;
    for (const calc of application.fieldCalculations) {

        if (calc.isPaymentLineItem) {  //contributes to the payment sum
            if (calc.error)
                throw new Error("Error in Payment Sum calculation \"" + calc.resultName + "\"");

            const numberResult = interpretArgument(calc.result, "NUMBER", application.fieldTree);  //must evaluate to a number, was checked above
            sum += numberResult;
        }
    }

    return sum;
}