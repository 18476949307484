import { DateUtils } from 'react-frontend-utils'
import { ThemeColors } from '../Theme'

/**
 * Dual use class for Patrons and Users.  For users, all information is available. For patrons, some data is not available.
 * 
 */

export class Community {
      
    static PatronStatusNotification = [

        {label: "Received",  defaultNotification: "Thank you for submitting an Application for CNAME. We will email you when your Application has been reviewed."},  
        {label: "Approved",  defaultNotification: "Congratulations, your Application for CNAME has been approved and your Membership is now active."},
        {label: "Suspended", defaultNotification: "Your Application for CNAME has been processed but your Membership is currently suspended. Please contact your community to resolve this issue."},
        {label: "Expired",   defaultNotification: "Your Application for CNAME has been processed."},
        {label: "Hold",      defaultNotification: "We received your Application for CNAME. Your Application is currently on hold."},
        {label: "Declined",  defaultNotification: "We received your Application for CNAME but your Application has not been processed. Please contact your community to resolve this issue."}          
    ];

       
    name;                           //Name of the community   
    version;                        //Not supplied for Patron
     
    extendedName;                   
    url;                            //Community webpage/support page
    supportUrl;                     //Where patrons go for help
    
    testingKey;                     //magic URL link for test mode
       
    adminEmail;                     //Not supplied for Patron
    supportEmail;                   //for patron support
    primaryColor;                   //Primary theme color, hex #rgb
    backgroundColor;                //Background theme color, hex #rgb
    
    instructions;                   //Not supplied for Patron

    webhook;                        //The webhook address
    mergedDocumentEnabled;          //true if merging is enabled

    preferredDateFormat;            //US or ISO8601
    
    assignedTemplate;               //Template assigned name, or null if none is assigned.  For Patrons, if a template is assigned, this value is "true"
    alternateTemplate;              //Alt template assigned name, or null if none. For Patrons, if a template is assigned, this value is "true"
    internalTemplate;               //Internal template name, or null if none. For Patrons this is not set.  For Community, this value is "true" if set
    supportReqAcceptedAlways;       //When true, alternate applications submitted anytime, otherwise only betweeen accept start/end dates

    acceptStartDate;                //When applications are accepted, null if any time (UTC)
    acceptEndDate;                  //When applications are no longer accepted, null if never (UTC)

    notAcceptingCustomText;         //Custom text to display when not accepting applications
    
    //These time fields are not passed from the back end - they are mererly for holding time on the front end and then computing the new date for the server
    acceptStartTime = "00:00";
    acceptEndTime = "23:59";
    
    hasPPSync;                      //True if we can sync with PoolPass
    isFullService;                  //true if client is full service
    
    adminReceivesApplicationCopy;       //True if the admin gets a email of the application
    adminReceivesSupportRequestCopy;    //True if the admin gets a email of the support request
    adminReceivesMergedDocument;        //True if the admin gets a email of the merged document

    emailNotifications;                         // Array of notifications, each index corresponds to one of the PatronStatusNotification labels above
    applicationSupportTicketLinkDisabled;       //link disabled when Patron is notified of Application status by email
    supportSupportTicketLinkDisabled;           //link disabled when Patron is notified of Support request status by email


    allowsNewMemberships;   //true if new Memberships are allowed (without placeholders or existing Memberships) - default false

    newMembershipGuestPassesSet;                    //the number of guest passes that new/replaced Memberships should receive when created (default 0)
    
    //The two settings below are mutually exclusive
    existingMembershipGuestPassesIncrement;             //the number of guest passes that updating Memberships should receive as an increase, mutually exclusive with below - null does nothing
    existingMembershipGuestPassesSet;                  //the number of guest passes that updating Memberships should receive, mutually exclusive with above - null does nothing
    
    //The three settings below are mutually exclusive
    existingMembershipExpirationDateSet = null;                //fixed date when the existing Membership should expire, null for never if the other parameters below are not set
    existingMembershipExpirationDateDeltaFromPrevious;  //delta time, in days, when the existing Membership expiration date should be extended, from their previous expiration date - null to ignore, 0 to leave unchanged
    existingMembershipExpirationDateDeltaFromNow;       //delta time, in days, when the existing Membership expiration date should be set, from the submit date - null to ignore
    existingMembershipExpirationDateEndMonthThisYear;   //month of the current year when the existing Membership should expire, null to ignore
    ignoreMembershipsThatNeverExpire;                   //if true, nothing is done to an existing Membership that already is set to never expire
    
    //The two settings below are mutually exclusive
    newMembershipExpirationDateSet = null;                 //fixed date when the new Membership should expire - null for never unless parameter below is set
    newMembershipExpirationDateDeltaFromNow;            //delta time, in days, when the new Membership expiration date should be set, from the submit date
    newMembershipExpirationDateEndMonthThisYear;        //month of the current year when the new Membership should expire, null to ignore  

    newMemberEntryPassesSet;                             //number of entry passes a new Member should receive, -1 for unlimited (default)
    
    //The two settings below are mutually exclusive
    existingMemberEntryPassesSet;                      //number of entry passes an existing Member should receive, -1 for unlimited, null to ignore
    existingMemberEntryPassesIncrement;                     //number of entry passes to increment for an existing Member, null to ignore
    ignoreMembersWithUnlimitedEntryPasses;                  //if true, nothing is done to an existing Member that already has unlimited entry passes
    
    newMemberBarcodeType = null;
    newMemberBarcodeDigits = 5;                         //default (min 1)
    newMemberBarcodeMinValue = 10001;                   //default (min 0)
     
    newMembershipExtraGuestMemberPasses = 0;

    suppressPassPrinting;
    suppressEPasses;
    suppressLimitedMemberPassPrinting;
     
    constructor(json) {
        if (json) { 
            this.name = json.name;
            this.version = json.version;

            this.extendedName = json.extendedName;
            this.url = json.url;
            this.supportUrl = json.supportUrl;
            this.testingKey = json.testingKey;
            
            this.isoCurrency = json.isoCurrency;
            this.accountNo = json.accountNo;
            this.instructions = json.instructions;            
            this.adminEmail = json.adminEmail;    
            this.supportEmail = json.supportEmail;
            
            this.primaryColor = json.primaryColor;
            if (!this.primaryColor)
                this.primaryColor = '#000000';  //black

            this.backgroundColor = json.backgroundColor;
            if (!this.backgroundColor)
                this.backgroundColor = '#ffffff'; //white

            this.version = json.version;
            this.assignedTemplate = json.assignedTemplate;
            this.alternateTemplate = json.alternateTemplate;
            this.internalTemplate = json.internalTemplate;
            this.supportReqAcceptedAlways = json.supportReqAcceptedAlways;
            this.webhook = json.webhook;
            this.mergedDocumentEnabled = json.mergedDocumentEnabled;
            this.adminReceivesMergedDocument = json.adminReceivesMergedDocument;

            this.emailNotifications = json.emailNotifications ? json.emailNotifications : [];
            this.applicationSupportTicketLinkDisabled = json.applicationSupportTicketLinkDisabled;
            this.supportSupportTicketLinkDisabled = json.supportSupportTicketLinkDisabled; 

            this.allowsNewMemberships = json.allowsNewMemberships;
            
            this.preferredDateFormat = json.preferredDateFormat;
                   
            if (json.acceptStartDate) {
                this.acceptStartDate = DateUtils.parseJsonDate(json.acceptStartDate); 
                this.acceptStartTime = DateUtils.twentyFourHourFormat(this.acceptStartDate);
            }
            if (json.acceptEndDate) {
                this.acceptEndDate = DateUtils.parseJsonDate(json.acceptEndDate); 
                this.acceptEndTime = DateUtils.twentyFourHourFormat(this.acceptEndDate);
            }
            this.notAcceptingCustomText = json.notAcceptingCustomText;
            
            this.hasPPSync = json.hasPPSync;
            this.isFullService = json.isFullService;
            this.adminReceivesApplicationCopy = json.adminReceivesApplicationCopy;
            this.adminReceivesSupportRequestCopy = json.adminReceivesSupportRequestCopy;
            
            this.newMembershipGuestPassesSet = json.newMembershipGuestPassesSet;                    
            
            this.existingMembershipGuestPassesIncrement = json.existingMembershipGuestPassesIncrement;             
            this.existingMembershipGuestPassesSet = json.existingMembershipGuestPassesSet;                 
    
            if (json.existingMembershipExpirationDateSet)
                this.existingMembershipExpirationDateSet = DateUtils.parseJsonDate(json.existingMembershipExpirationDateSet);
            
            this.existingMembershipExpirationDateDeltaFromPrevious = json.existingMembershipExpirationDateDeltaFromPrevious;  
            this.existingMembershipExpirationDateDeltaFromNow = json.existingMembershipExpirationDateDeltaFromNow;    
            this.existingMembershipExpirationDateEndMonthThisYear = json.existingMembershipExpirationDateEndMonthThisYear;   
            this.ignoreMembershipsThatNeverExpire = json.ignoreMembershipsThatNeverExpire;              
    
            if (json.newMembershipExpirationDateSet)
                this.newMembershipExpirationDateSet = DateUtils.parseJsonDate(json.newMembershipExpirationDateSet);
            
            this.newMembershipExpirationDateDeltaFromNow = json.newMembershipExpirationDateDeltaFromNow;            
            this.newMembershipExpirationDateEndMonthThisYear = json.newMembershipExpirationDateEndMonthThisYear;

            this.newMemberEntryPassesSet = json.newMemberEntryPassesSet; 
    
            this.existingMemberEntryPassesSet = json.existingMemberEntryPassesSet;                       
            this.existingMemberEntryPassesIncrement = json.existingMemberEntryPassesIncrement;               
            this.ignoreMembersWithUnlimitedEntryPasses = json.ignoreMembersWithUnlimitedEntryPasses;        
            
            this.newMemberBarcodeType = json.newMemberBarcodeType;
            this.newMemberBarcodeDigits = json.newMemberBarcodeDigits;
            this.newMemberBarcodeMinValue = json.newMemberBarcodeMinValue;
            
            this.newMembershipExtraGuestMemberPasses = json.newMembershipExtraGuestMemberPasses;

            this.suppressPassPrinting = json.suppressPassPrinting;
            this.suppressEPasses = json.suppressEPasses;
            this.suppressLimitedMemberPassPrinting = json.suppressLimitedMemberPassPrinting;
    
        }
    }
    
    
    //Prior to posting to the server, we may have multiple mutually exclusive fields set as the user made various choices. We need to pick the correct one
    //and remove the others so they remain mutually exclusive.  Set fields to undefined to remove from json.
    //The accept start and end times also need to be appended to the dates to post back to the server.
    prepareForPost(preferredDateFormat, newMembershipExpirationDateType, existingMembershipExpirationDateType, existingMembershipGuestPassesType, existingMemberEntryPassesType, newMemberBarcodeMode, serviceLevel) {
        
        this.preferredDateFormat = preferredDateFormat;

        if (this.acceptStartDate)
            this.acceptStartDate += "T" + this.acceptStartTime + ":00";
        
        if (this.acceptEndDate)
            this.acceptEndDate += "T" + this.acceptEndTime + ":00";
        
        this.acceptStartTime = undefined;
        this.acceptEndTime = undefined;
        
        switch (newMembershipExpirationDateType) {
            case "Calendar":
                this.newMembershipExpirationDateDeltaFromNow = undefined; 
                this.newMembershipExpirationDateEndMonthThisYear = undefined;                    
                break;
            case "DeltaNow":
                this.newMembershipExpirationDateSet = undefined;
                this.newMembershipExpirationDateEndMonthThisYear = undefined;
                break;
            case "EndMonthThisYear":
                this.newMembershipExpirationDateSet = undefined;
                this.newMembershipExpirationDateDeltaFromNow = undefined;
                break;
            default:
                throw new Error("Invalid newMembershipExpirationDateType: " + newMembershipExpirationDateType);
        }
        
        
        switch (existingMembershipExpirationDateType) {
            
            case "NoChange":
                this.existingMembershipExpirationDateDeltaFromPrevious = 0;  //set this to zero for no change
                this.existingMembershipExpirationDateSet = undefined;
                this.existingMembershipExpirationDateDeltaFromNow = undefined;
                this.existingMembershipExpirationDateEndMonthThisYear = undefined;
                break;
                
            case "Calendar":
                this.existingMembershipExpirationDateDeltaFromPrevious = undefined; 
                this.existingMembershipExpirationDateDeltaFromNow = undefined;
                this.existingMembershipExpirationDateEndMonthThisYear = undefined;
                break;
           
            case "DeltaPrevious":
                this.existingMembershipExpirationDateSet = undefined;
                this.existingMembershipExpirationDateDeltaFromNow = undefined;
                this.existingMembershipExpirationDateEndMonthThisYear = undefined;
                break;
                
            case "DeltaNow":
                this.existingMembershipExpirationDateDeltaFromPrevious = undefined;
                this.existingMembershipExpirationDateSet = undefined;
                this.existingMembershipExpirationDateEndMonthThisYear = undefined;
                break;   

            case "EndMonthThisYear":
                this.existingMembershipExpirationDateSet = undefined; 
                this.existingMembershipExpirationDateDeltaFromNow = undefined;
                this.existingMembershipExpirationDateDeltaFromPrevious = undefined;
                break;
                
            default:
                throw new Error("Invalid existingMembershipExpirationDateType: " + existingMembershipExpirationDateType);
        }
        
        switch (existingMembershipGuestPassesType) {
            case "NoChange":
                this.existingMembershipGuestPassesIncrement = 0; //set this to zero for no change
                this.existingMembershipGuestPassesSet = undefined;
                break;
                
            case "Set":
                this.existingMembershipGuestPassesIncrement = undefined;
                if (!this.existingMembershipGuestPassesSet)
                    this.existingMembershipGuestPassesSet = 0;
                break;
            
            case "Increment":
                this.existingMembershipGuestPassesSet = undefined;
                if (!this.existingMembershipGuestPassesIncrement)
                    this.existingMembershipGuestPassesIncrement = 0;
                break;
                
            default:
                throw new Error("Invalid existingMembershipGuestPassesType: " + existingMembershipGuestPassesType);
                
        }
        
        switch (existingMemberEntryPassesType) {
            case "NoChange":
                this.existingMemberEntryPassesIncrement = 0; //set this to zero for no change
                this.existingMemberEntryPassesSet = undefined;
                break;
                
            case "Set":
                this.existingMemberEntryPassesIncrement = undefined;
                if (!this.existingMemberEntryPassesSet)
                    this.existingMemberEntryPassesSet = -1;
                break;
            
            case "Increment":
                this.existingMemberEntryPassesSet = undefined;
                if (!this.existingMemberEntryPassesIncrement)
                    this.existingMemberEntryPassesIncrement = 0;
                break;
                
            default:
                throw new Error("Invalid existingMemberEntryPassesType: " + existingMemberEntryPassesType);
                
        }
        
        if (newMemberBarcodeMode === "None")
            this.newMemberBarcodeType = null;
        else
            this.newMemberBarcodeType = newMemberBarcodeMode;   //Use the exact string value
          
        switch (serviceLevel) {
            case "fullService":
                this.isFullService = true;
                break;
            default:
                this.isFullService = false;
        }        
    }
    
    existingMembershipExpirationDateType() {
        //Determine the "types" based on the mutually exclusive variables 
        if (this.existingMembershipExpirationDateDeltaFromPrevious === 0)  //this value if set, and zero, means no change  
            return "NoChange";  
        else if (this.existingMembershipExpirationDateDeltaFromNow != null && this.existingMembershipExpirationDateDeltaFromNow >= 0)  //this value if set, means DeltaNow (from application submit)
            return "DeltaNow";  
        else if (this.existingMembershipExpirationDateDeltaFromPrevious > 0) //this value if set means Delta from previous
            return "DeltaPrevious";
        else if (this.existingMembershipExpirationDateEndMonthThisYear != null)  //this value if set, means set to end of month (this year)
            return "EndMonthThisYear";
        else
            return "Calendar";  //if no other fields set, we use calendar date
                    
    }
    
    newMembershipExpirationDateType() {
        
        if (this.newMembershipExpirationDateDeltaFromNow != null && this.newMembershipExpirationDateDeltaFromNow >= 0)
            return "DeltaNow";
        else if (this.newMembershipExpirationDateEndMonthThisYear != null)
            return "EndMonthThisYear";
        else
            return "Calendar";  //if this field has a value, then we use Delta date, otherwise use Calendar date
    }
    
    existingMembershipGuestPassesType() {
        if (this.existingMembershipGuestPassesIncrement === 0) //if this value is set, and zero, means no change
            return "NoChange";
        else if (this.existingMembershipGuestPassesIncrement > 0)
           return "Increment";
        else
            return "Set";
    }
    
         
    existingMemberEntryPassesType() {
        if (this.existingMemberEntryPassesIncrement === 0) //if this value is set, and zero, means no change
            return "NoChange";
        else if (this.existingMemberEntryPassesIncrement > 0)
            return "Increment";
        else
            return "Set";
    }
    
    newMemberBarcodeMode() {
        
        if (!this.newMemberBarcodeType)
            return "None";
        else
            return this.newMemberBarcodeType;
    }
    
    serviceLevel() {
        if (this.isFullService)
            return "fullService";
        else
            return "selfService";
    }
    
    
    static createNew(name) {
        
        const c = new Community();
        c.name = name;
        c.allowsNewMemberships = true;
        c.newMembershipGuestPassesSet = 0;
        c.existingMembershipGuestPassesIncrement = 0;  //no change
        c.newMemberEntryPassesSet = -1;  //unlimited
        c.existingMemberEntryPassesIncrement = 0;  //no change
        c.newMembershipExtraGuestMemberPasses = 0;
        c.newMemberBarcodeType = "Random_Numeric";
        c.isoCurrency = "";
        c.accountNo = "";
        c.preferredDateFormat = "US";
        c.primaryColor = '#000000';  //black
        c.backgroundColor = '#ffffff';  //white
        c.hasPPSync = true;
        c.isFullService = false;


        let thisYear = DateUtils.currentYear();
        const isAfterOct1 = (new Date()) > DateUtils.parseJsonDate(thisYear + "-10-01");
        if (isAfterOct1)
            thisYear += 1;

        c.acceptStartDate = null;   //anytime
        c.acceptEndDate = null;     //never
        c.acceptStartTime = "00:00";
        c.acceptEndTime = "23:59";
        c.notAcceptingCustomText = null;
        c.newMembershipExpirationDateSet = undefined
        c.existingMembershipExpirationDateSet = undefined;
        c.existingMembershipExpirationDateDeltaFromPrevious = undefined;
        c.newMembershipExpirationDateDeltaFromNow = undefined;

        c.existingMembershipExpirationDateEndMonthThisYear = 12;
        c.newMembershipExpirationDateEndMonthThisYear = 12;

        c.adminReceivesApplicationCopy = true;
        c.adminReceivesSupportRequestCopy = true;
        c.adminReceivesMergedDocument = false;

        c.emailNotifications = [];
        c.applicationSupportTicketLinkDisabled = false;
        c.supportSupportTicketLinkDisabled = true;
        c.suppressPassPrinting = false;
        c.suppressEPasses = false;
        c.suppressLimitedMemberPassPrinting = true;

        return c;
    }
    
    
    //Make a deep copy of the selected community to be posted back to the server. Most fields are the same type, but the source "other" Community uses Date objects
    //and the returned Community we use JsonDateString for proper posting back to the server.  If direct is true, we're making a copy of the copy (right before posting)
    //so if the post fails we don't screw up our original.  In this case we just make a direct copy without date conversion
    static copyForPosting(other, direct = false) {
        const c = new Community();
        c.name = other.name;
        c.extendedName = other.extendedName;
        c.version = other.version;
        c.url = other.url;
        c.supportUrl = other.supportUrl;
        c.adminEmail = other.adminEmail;
        c.supportEmail = other.supportEmail;
        c.instructions = other.instructions;
        c.assignedTemplate = other.assignedTemplate;
        c.alternateTemplate = other.alternateTemplate;
        c.internalTemplate = other.internalTemplate;
        c.supportReqAcceptedAlways = other.supportReqAcceptedAlways;
        c.testingKey = other.testingKey;

        c.isoCurrency = other.isoCurrency; 
        c.accountNo = other.accountNo;           
        c.primaryColor = other.primaryColor;
        c.backgroundColor = other.backgroundColor;

        c.webhook = other.webhook;
        c.mergedDocumentEnabled = other.mergedDocumentEnabled;
        c.preferredDateFormat = other.preferredDateFormat;
        
        if (direct) {
            c.acceptStartDate = other.acceptStartDate;
            c.acceptEndDate = other.acceptEndDate;
        }
        else {
            //Convert the source from Date objects to JsonDateString, we post JsonDateStrings back to server - 
            c.acceptStartDate = other.acceptStartDate ? DateUtils.jsonDateString(other.acceptStartDate) : null;
            c.acceptEndDate = other.acceptEndDate ? DateUtils.jsonDateString(other.acceptEndDate) : null;
        }

        c.notAcceptingCustomText = other.notAcceptingCustomText;
        c.acceptStartTime = other.acceptStartTime;
        c.acceptEndTime = other.acceptEndTime;
        
        c.adminReceivesApplicationCopy = other.adminReceivesApplicationCopy;
        c.adminReceivesSupportRequestCopy = other.adminReceivesSupportRequestCopy;
        c.adminReceivesMergedDocument = other.adminReceivesMergedDocument;
        c.allowsNewMemberships = other.allowsNewMemberships;
        c.hasPPSync = other.hasPPSync;
        c.isFullService = other.isFullService;

        c.applicationSupportTicketLinkDisabled = other.applicationSupportTicketLinkDisabled;
        c.supportSupportTicketLinkDisabled = other.supportSupportTicketLinkDisabled; 

        
        c.newMembershipGuestPassesSet = other.newMembershipGuestPassesSet;                    
    
        c.existingMembershipGuestPassesIncrement = other.existingMembershipGuestPassesIncrement;             
        c.existingMembershipGuestPassesSet = other.existingMembershipGuestPassesSet;                 

        if (direct) {
            c.existingMembershipExpirationDateSet = other.existingMembershipExpirationDateSet;
            c.newMembershipExpirationDateSet = other.newMembershipExpirationDateSet;
        }
        else {
            c.existingMembershipExpirationDateSet = other.existingMembershipExpirationDateSet ? DateUtils.jsonDateString(other.existingMembershipExpirationDateSet) : null;
            c.newMembershipExpirationDateSet = other.newMembershipExpirationDateSet ? DateUtils.jsonDateString(other.newMembershipExpirationDateSet) : null;
        }

        c.existingMembershipExpirationDateDeltaFromPrevious = other.existingMembershipExpirationDateDeltaFromPrevious;  
        c.existingMembershipExpirationDateDeltaFromNow = other.existingMembershipExpirationDateDeltaFromNow;       
        c.existingMembershipExpirationDateEndMonthThisYear = other.existingMembershipExpirationDateEndMonthThisYear;
        c.ignoreMembershipsThatNeverExpire = other.ignoreMembershipsThatNeverExpire;        

        c.newMembershipExpirationDateDeltaFromNow = other.newMembershipExpirationDateDeltaFromNow;            
        c.newMembershipExpirationDateEndMonthThisYear = other.newMembershipExpirationDateEndMonthThisYear;  

        c.newMemberEntryPassesSet = other.newMemberEntryPassesSet; 

        c.existingMemberEntryPassesSet = other.existingMemberEntryPassesSet;                       
        c.existingMemberEntryPassesIncrement = other.existingMemberEntryPassesIncrement;               
        c.ignoreMembersWithUnlimitedEntryPasses = other.ignoreMembersWithUnlimitedEntryPasses;            
        
        
        c.newMemberBarcodeType = other.newMemberBarcodeType;
        c.newMemberBarcodeDigits = other.newMemberBarcodeDigits;
        c.newMemberBarcodeMinValue = other.newMemberBarcodeMinValue;
        
        c.newMembershipExtraGuestMemberPasses = other.newMembershipExtraGuestMemberPasses;

        c.emailNotifications = [...other.emailNotifications];     // deep array copy
        c.suppressPassPrinting = other.suppressPassPrinting;
        c.suppressEPasses = other.suppressEPasses;
        c.suppressLimitedMemberPassPrinting = other.suppressLimitedMemberPassPrinting;
        
        return c;
    }
    
    
    static Status = {

        INACTIVE:     {label: "Inactive", color: 'gray', tooltip: "No template assigned to Community", patronReason: "We are sorry, your community is not currently accepting Applications"},  
        WAITING:      {label: "Waiting", color: 'blue', tooltip: "Not yet accepting Applications", patronReason: "You are early! Your community is not accepting Applications yet. Please check back later."},
        FINISHED:     {label: "Finished", color: ThemeColors.darkRed, tooltip: "Applications no longer accepted", patronReason: "We are sorry, your community is no longer accepting Applications.  Please check back later."},
        ACCEPTING:    {label: "Accepting", color: 'green', tooltip: "Actively accepting Applications", patronReason: "Accepting Applications"}     
    };
    
    //Return an Status item 
    status() {
        
        //For patrons - this value is just "true", for users this is the template name
        if (!this.assignedTemplate)
            return Community.Status.INACTIVE;
        
        const now = (new Date()).getTime();  //UTC
        
        if (this.acceptStartDate) {
            if (now < this.acceptStartDate.getTime())
                return Community.Status.WAITING;
        }
        
        if (this.acceptEndDate) {
            if (now > this.acceptEndDate.getTime())
                return Community.Status.FINISHED;
        }
        
        return Community.Status.ACCEPTING;              
    }
    
    
    //Create counts for the states of all communities in the provided array of communities.  The Object "aggregate" below is populated and returned
    static aggregate(communities) {
        
        const aggregate = {accepting:  {count: 0, names: []}, 
                           waiting:    {count: 0, names: []}, 
                           finished:   {count: 0, names: []}, 
                           inactive:   {count: 0, names: []}
                          };
                
        communities.forEach(community => {
            switch (community.status()) {
                case Community.Status.INACTIVE:
                    aggregate.inactive.count++;
                    aggregate.inactive.names.push(community.name);
                    break;
                case Community.Status.WAITING:
                    aggregate.waiting.count++;
                    aggregate.waiting.names.push(community.name);
                    break;
                case Community.Status.FINISHED:
                    aggregate.finished.count++;
                    aggregate.finished.names.push(community.name);
                    break;
                case Community.Status.ACCEPTING:
                    aggregate.accepting.count++; 
                    aggregate.accepting.names.push(community.name);
                    break;
                default:
                    console.log.error("Invalid community status");
            }
            
        });
        
        return aggregate;
    }
    
    
}