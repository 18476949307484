import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';

export const fieldCalculationHelp = () => {
   
    const calculations = [
        
        {type: "LITERAL",    name: "String",                      description: "Literal text, must be double-quoted",                              example: "\"This is text\", \"123-Text\""},
        {type: "LITERAL",    name: "Number",                      description: "Literal numeric value, decimals or scientific notation",           example: "4.5, -2, 1e5, -6.0"},
        {type: "LITERAL",    name: "Boolean",                     description: "Converted to numeric, true = 1, false = 0",                        example: "false"},
        {type: "OPERATOR",   name: "Arithmetic",                  description: "Standard Arithmetic Operators + - * / for numeric operands",       example: "5 + 6"},
        {type: "OPERATOR",   name: "Modulus",                     description: "Modulus Operator % for numeric operands",                          example: "10 % 2"},
        {type: "OPERATOR",   name: "Exponential",                 description: "Exponent Operator ^, value raised to a power",                     example: "5^2, 8^0.5"},
        {type: "OPERATOR",   name: "String Concatenation",        description: "The + operator combines strings, when operands are strings",       example: "\"The\" + \" \" + \"End\""},
        {type: "OPERATOR",   name: "Equals",                      description: "The == operator compares numbers or strings for equality",         example: "5 == 5, \"The\" == \"The\""},
        {type: "OPERATOR",   name: "Not Equals",                  description: "The != operator compares numbers or strings for not-equality",     example: "5 != 3, \"The\" != \"End\""},
        {type: "OPERATOR",   name: "Greater",                     description: "Comparison > (greater than) or >= (greater than or equal to)",     example: "5 > 2, 5 >= 5"},
        {type: "OPERATOR",   name: "Less",                        description: "Comparison < (less than) or <= (less than or equal to)",           example: "2 < 5, 5 <= 5"},
        {type: "OPERATOR",   name: "Not",                         description: "The ! operator results in 1 if the operand is 0 or 0 otherwise",   example: "!(5-5)"},
        {type: "OPERATOR",   name: "And",                         description: "The && operator results in 1 if both operands are non-zero",       example: "6 && 2"},
        {type: "OPERATOR",   name: "Or",                          description: "The || operator results in 1 if either operands are non-zero",     example: "6 || 2"},
        {type: "OPERATOR",   name: "Child",                       description: "The . operator selects a child field by index or name",            example: "fieldgroup.2, fieldgroup.\"firstfield\""},
        {type: "GROUP",      name: "Parenthesis",                 description: "Groups calculations together for priority evaluation",             example: "(5 + 3) * 2"},
        {type: "GROUP",      name: "Curly Braces",                description: "References a previous calculation by name",                        example: "{Calculation1} * 4"}

    ];
             
    const functions = [
        
        {name: "round(num)",        arguments: [{name: "num", description: "operand (Number)"}], 
                returns: "Number",   discussion: "Rounds the argument to the nearest whole number"},

        {name: "abs(num)",          arguments: [{name: "num", description: "operand (Number)"}], 
                returns: "Number",  discussion: "Returns the absolute value of the argument"},

        {name: "countIfEq(prefix, val)",    arguments: [{name: "prefix", description: "prefix of the field (String)"}, {name: "val", description: "value to compare against (String or Number)"}], 
                returns: "Number",  discussion: "Find all fields that start with the string 'prefix', and add to the count if the field value is equal to 'val'"},
               
        {name: "sumEach(prefix)",    arguments: [{name: "prefix", description: "prefix of the field (String)"}], 
                returns: "Number",  discussion: "Find all fields that start with the string 'prefix', and sum the field values (which must be convertable to Numbers)"},

        {name: "countEach(prefix)",    arguments: [{name: "prefix", description: "prefix of the field (String)"}], 
                returns: "Number",  discussion: "Count all fields that start with the string 'prefix'"},

        {name: "sibling(name)",    arguments: [{name: "name", description: "name of the sibling field (String)"}], 
                returns: "Variable",  discussion: "Generally used to find a sibling field within a Cloning Group, finds the field that has the same parent as the field being evaluated"},
  
        {name: "children(arg)",    arguments: [{name: "arg", description: "a variable"}],   
               returns: "Number",  discussion: "Returns the number of children for the field. If the field is not a field group, returns 0"},
    
        {name: "exists(prefix, falseResult)",    arguments: [{name: "prefix", description: "prefix of the field (String)"}, {name: "falseResult", description: "result when the if evaluation is false"}],   
               returns: "Result",  discussion: "If a field exists that starts with the prefix 'prefix', return the field Variable, otherwise return the falseResult"},
    
        {name: "if(eval, trueResult, falseResult)",    arguments: [{name: "eval", description: "an expression that evaluates to a true (non-zero) value or a false (zero) value"}, {name: "trueResult", description: "result when the if evaluation is true"}, {name: "falseResult", description: "result when the if evaluation is false"}], 
                returns: "Result",  discussion: "Evaluate the argument in 'eval', if the result is a non-zero value, return the 'trueResult' argument, otherwise return the 'falseResult' argument"},
               
        {name: "number(arg)",    arguments: [{name: "arg", description: "A variable or literal that can be converted to a number"}], 
                returns: "Number",  discussion: "Tries to coerce the variable or literal argument to a number. For instance, a string value that contains just a number can be coerced"},
            
        {name: "random(digits, type)",    arguments: [{name: "digits", description: "Number of digits in the random number"}, {name: "type", description: "numeric (0-9) or alphanumeric (0-9, A-Z)"}], 
                returns: "String",  discussion: "Generates a random string of the specified length and type, no leading zeroes"},
            
        {name: "now()",    arguments: [], 
                returns: "Number",  discussion: "The current time, as seconds since the epoch (Jan 1, 1970 00:00:00 UTC)"},
                
        {name: "dateVal(dateStr)", arguments: [{name: "dateStr", description: "A UTC date string in the format YYYY-MM-DD HH:mm:ss, where MM can be the numeric or name of the month"}], 
                returns: "Number",  discussion: "Parses 'dateStr' and converts to a UTC time in seconds since the epoch (Jan 1, 1970 00:00:00 UTC)"},

        {name: "dateStr(dateVal)", arguments: [{name: "dateVal", description: "A date expressed as seconds since the epoch (Jan 1, 1970 00:00:00 UTC)"}], 
                returns: "String",  discussion: "Formats 'dateVal' as a string in the form YYYY-MMM-DD, if the date is not valid, returns an empty string"},

        {name: "age(birthdate)", arguments: [{name: "birthdate", description: "A date expressed as seconds since the epoch (Jan 1, 1970 00:00:00 UTC)"}], 
                returns: "Number",  discussion: "Returns the age in years based on the date. Invalid dates or dates after the current time result in a value of NaN"},

        {name: "str(arg)",    arguments: [{name: "arg", description: "A variable or literal that can be converted to a string"}], 
                returns: "String",  discussion: "Tries to coerce the variable or literal argument to a string. For instance, a numeric value is converted to its string representation"},
   
        {name: "strlen(string)",    arguments: [{name: "string", description: "a string to compute the length of"}], 
                returns: "Number",  discussion: "Returns the number of characters in the string"},

        {name: "contains(stringToSearch, stringToFind)",    arguments: [{name: "stringToSearch", description: "the string to look in"}, {name: "stringToFind", description: "the string to search for"}], 
                returns: "Number",  discussion: "If the string 'stringToSearch' has the substring 'stringToFind', returns 1, otherwise returns 0"},

        {name: "strSplit(stringToSplit, delimiter, index)",    arguments: [{name: "stringToSplit", description: "the string to split"}, {name: "delimiter", description: "the character/string to split on"}, {name: "index", description: "which piece of the split string to return"}],
                returns: "String",  discussion: "Splits the string 'stringToSplit' into pieces using the 'delimiter' and returns the 'index' piece of the split string. Index is 0-based. If the index is out of range, returns an empty string"},
   
        {name: "strSlice(stringToSlice, start, end)",    arguments: [{name: "stringToSlice", description: "the string to slice"}, {name: "start", description: "the index to start slicing (inclusive)"}, {name: "end", description: "the index to end slicing (exclusive)"}],
                returns: "String",  discussion: "Returns the substring of 'stringToSlice' starting at 'start' and ending at 'end', exclusive. If 'end' is out of range, returns the rest of the string. If either index is negative, the index is treated as an offset from the end of the string"},
    
    ]; 
   
    const style = {
       table: {
            border: '1px solid gray',
            borderCollapse: 'collapse',
        },
        header: {
            textAlign: 'left',
            padding: 2,
            paddingRight: 10,
            fontSize: '10pt',
            fontWeight: 'bold'
        },
        cell: {
            padding: 5,
            paddingRight: 8,
            fontSize: '10pt',
            fontWeight: 'normal'
        },
        footer: {
            marginTop: 5,
            marginLeft: 10,
            fontSize: 11
        }
    };


    return (<div style={{display: 'flex', gap: 20}}>
                <div style={{maxWidth: '50%'}}>
                    <Typography variant="h5">Field Calculations</Typography>
                    <Typography variant="body2" style={{marginBottom: 20}}>Field Calculations operate on Variables (values from Fields) and Literals (numbers or text strings),
                        and can reference previous calculations. Boolean values and results are converted to numbers: 1 (true) or 0 (false).</Typography>

                    <table style={{...style.table, width: '100%'}}>
                        <tbody>
                            <tr style={style.table}>
                            <th style={style.header}>Type</th>
                            <th style={style.header}>Name</th>
                            <th style={style.header}>Description</th> 
                            <th style={style.header}>Examples</th>
                            </tr>

                            {calculations.map((calc, index) => { return (<tr key={index} style={style.table}>
                                                                            <td style={style.cell}>{calc.type}</td>
                                                                            <td style={style.cell}>{calc.name}</td>
                                                                            <td style={style.cell}>{calc.description}</td>
                                                                            <td style={style.cell}>{calc.example}</td>
                                                                        </tr>);})
                            }
                        </tbody>
                    </table> 

                    <Typography variant="h5">Hidden Logic</Typography>
                    <Typography variant="body2" style={{marginTop: 20}}>Hidden logic uses the same calculations and functions as Field Calculations. All Field Calculations are processed 
                                first. Then each field that has hidden logic is evaluated. The hidden logic can reference any other Field value or any Field Calcuation.</Typography>
 
                </div>

                <div>
                    <Typography variant="h5">Functions</Typography>
                    <Typography variant="body2" style={{marginBottom: 20}}>Functions can be called with arguments and literals as arguments, and return a literal or a variable.</Typography>

                    {functions.map((func, index) => { return (<div key={index} style={{marginBottom: 10, borderTop: '1px solid lightGray'}}>
                                                                <div style={{display: "flex", gap: 5, alignItems: 'bottom'}}>
                                                                    <Typography variant="body2" style={{fontFamily: 'monospace', color: 'blue', fontWeight: 'bold'}}>{func.name + " ▻ "}</Typography>
                                                                    <Typography variant="body2" style={{fontFamily: 'monospace', fontStyle: 'italic'}}>{func.returns}</Typography>
                                                                </div>
                                                                <Typography style={{marginLeft: 15, fontWeight: 10}} variant="body2">{func.discussion}</Typography>
                                                                {func.arguments.map((arg, index) => 
                                                                    <div style={{display: "flex", gap: 5, marginLeft:10, alignItems: 'bottom'}}>
                                                                        <Typography variant="body2" style={{fontWeight: 'bold'}}>{arg.name}</Typography>
                                                                        <Typography variant="body2" style={{fontStyle: 'italic'}}>{arg.description}</Typography>
                                                                    </div>)
                                                                }
                                                            </div>)
                                                    }
                    )}
                </div>
        </div>
    );
}
	

const FieldCalculationTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'black',
        maxWidth: 3000,
        fontSize: theme.typography.pxToRem(10),
        border: '2px solid gray',
        padding: 5
    }
}))(Tooltip);


export default FieldCalculationTooltip